import styled from "styled-components";

const StyledCareerFollow = styled.div`
	.career_detail-follow-up-section {
		text-align: center;
		padding: 70px 0;
	}
	@media only screen and (max-width: 1200px) {
		.career_detail-follow-up-section {
			padding: 50px 20px;
			box-sizing: border-box;
		}
	}
`;

export default StyledCareerFollow;
