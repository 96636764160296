import React from "react";
import { string, array, element, oneOfType, arrayOf } from "prop-types";
import { MDXRenderer } from "gatsby-plugin-mdx";
import StyledCommonContent from "./style";
import CallToAction from "../CallToAction";

const CommonContent = ({
	mainClass,
	buttons,
	headline,
	content,
	wrapperClass
}) => {
	return (
		<StyledCommonContent className={wrapperClass}>
			<div className={mainClass}>
				<div className="content">
					<div className="headline">
						<h1>{headline}</h1>
					</div>
					<MDXRenderer>{content}</MDXRenderer>
					{buttons.map(button => {
						return (
							<CallToAction key={`${button.props.link}`}>{button}</CallToAction>
						);
					})}
				</div>
			</div>
		</StyledCommonContent>
	);
};

CommonContent.defaultProps = {
	mainClass: "common-content",
	buttons: [],
	headline: "",
	wrapperClass: ""
};

CommonContent.propTypes = {
	content: oneOfType([string, array]).isRequired,
	buttons: arrayOf(element),
	mainClass: string,
	headline: string,
	wrapperClass: string
};

export default CommonContent;
