import React from "react";
import PropTypes from "prop-types";
import Image from "../components/Image";
import ContentRow from "../components/ContentRow";
import ButtonHashLink from "../components/ButtonHashLink";
import Main from "../components/Main";
import FormattedContent from "../components/CommonContent/FormattedContent";
import CommonContent from "../components/CommonContent";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import StyledCareerDetailSection from "../components/templateStyles/StyledCareerDetailSection";
import StyledCareerFollow from "../components/templateStyles/StyledCareerFollow";

const CareerDetail = ({ pageContext }) => {
	return (
		<Layout pageContext={pageContext}>
			<SEO
				title={`${pageContext.title}`}
				description={pageContext.description}
				lang={pageContext.lang}
			/>
			<Main className="page-container">
				<ContentRow background="white" rowContentClass="no-padding">
					<div style={{ height: "80px" }} />
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding first-row">
					<StyledCareerDetailSection>
						<FormattedContent
							headline={pageContext.title}
							contentSize="big"
							content={pageContext.html}
							wrapperClass="career_detail-content"
							aligned="top"
						/>
						<div className="career_detail-image">
							<Image
								data={pageContext.cover}
								alt={pageContext.title}
								style={{ maxWidth: "800px", width: "100%" }}
							/>
						</div>
					</StyledCareerDetailSection>
				</ContentRow>
				<ContentRow background="green-light" rowContentClass="no-padding">
					<StyledCareerFollow>
						<CommonContent
							headline={pageContext.CAREER_DETAIL_INT_HEADLINE}
							content=""
							wrapperClass="career_detail-follow-up-section"
							buttons={[
								<ButtonHashLink
									link={`/${pageContext.lang}/${pageContext.MENU.CONTACT.URI}?formtype=hr#contact-form`}
									label={pageContext.CAREER_DETAIL_INT_CTA}
									classString="link green-font"
								/>
							]}
						/>
					</StyledCareerFollow>
				</ContentRow>
			</Main>
		</Layout>
	);
};

CareerDetail.propTypes = {
	pageContext: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array
		])
	).isRequired
};
export default CareerDetail;
