import styled from "styled-components";

const StyledAboutUsSection = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 50px 20px 35px 20px;

	.career_detail-content {
		width: 60%;
	}
	.career_detail-image {
		width: 40%;
		max-width: 460px;
		padding-left: 35px;
	}

	@media only screen and (max-width: 700px) {
		flex-direction: column;
		.career_detail-content {
			width: 100%;
			padding-bottom: 35px;
		}
		.career_detail-image {
			width: 100%;
			max-width: 300px;
			padding: 0;
			margin: auto;
		}
	}

	@media only screen and (max-width: 1200px) {
		padding-top: 0;
	}
`;

export default StyledAboutUsSection;
